.announcement-modal .modal-content {
  overflow: hidden;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25); }

.announcement-modal-media {
  padding: 0 1.5rem 2rem 1.5rem;
  text-align: center; }
  .announcement-modal-media img, .announcement-modal-media video {
    border-radius: .4rem; }

.announcement-modal-close {
  display: flex;
  justify-content: flex-end;
  padding: .5rem; }
  .announcement-modal-close svg path {
    fill: rgba(255, 255, 255, 0.5); }

.announcement-modal-media, .announcement-modal-close {
  background-color: #04254D; }

.announcement-modal-text {
  padding: 1rem 1.5rem; }

.announcement-modal-footer {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  padding: 1rem 1.5rem 2rem 1.5rem; }

.announcement-modal-title {
  font-size: 1.125rem;
  font-weight: 600;
  margin-bottom: .4rem; }
