.sidebar-nav {
  min-width: 11rem; }
  .sidebar-nav .nav-link {
    color: #848a9e;
    padding-left: 0;
    padding-right: 0; }
    .sidebar-nav .nav-link:hover {
      color: #3C404C; }
      .sidebar-nav .nav-link:hover .icon {
        background-color: #3C404C; }
    .sidebar-nav .nav-link.active {
      color: #2f80ed;
      font-weight: 600; }
      .sidebar-nav .nav-link.active .icon {
        background-color: #2f80ed; }
    .sidebar-nav .nav-link .icon {
      margin-right: .3rem;
      background-color: #848a9e; }

.password-tips-check .check {
  list-style-type: none;
  list-style-image: url("data:image/svg+xml,%0A%3Csvg width='16px' height='12px' viewBox='0 0 16 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: Sketch 43.2 (39069) - http://www.bohemiancoding.com/sketch --%3E%3Cdesc%3ECreated with Sketch.%3C/desc%3E%3Cdefs%3E%3C/defs%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='brown-check' fill-rule='nonzero' fill='%2300C853'%3E%3Cpolygon id='Path_62' points='5.02 9.436 1.313 5.724 0 6.977 5.02 11.986 15.762 1.253 14.448 0'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E"); }

.plan-group {
  display: flex;
  justify-content: center;
  gap: 2rem; }

.plan-item {
  width: 20rem;
  padding: 1rem 1.5rem;
  border: 1px solid rgba(218, 220, 226, 0.5);
  border-radius: 0.2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  .plan-item .price {
    display: block;
    margin-bottom: .8rem;
    font-size: 2rem;
    font-weight: 600;
    text-align: center; }
    .plan-item .price small {
      font-size: .9rem;
      font-weight: normal;
      color: #858B9E; }
  .plan-item ul {
    list-style-type: none;
    padding-left: 0; }
    .plan-item ul > li {
      background-image: url("data:image/svg+xml,%3Csvg stroke='%2328a745' fill='%2328a745' stroke-width='0' viewBox='0 0 16 16' class='text-success' height='22' width='22' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z'%3E%3C/path%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: left center;
      padding-left: 1.5rem;
      color: #60686f; }
  .plan-item .plan-status {
    display: inline-block;
    background-color: #DADCE2;
    padding: .5rem .8rem;
    font-weight: 600;
    border-radius: 3px; }
  .plan-item .plan-bottom {
    margin-top: 1rem;
    text-align: center; }

.btn-slack {
  border-color: rgba(218, 220, 226, 0.5) !important;
  background-color: #fff !important;
  color: inherit !important; }
  .btn-slack:hover {
    border-color: rgba(161, 166, 181, 0.5) !important; }
  .btn-slack .icon {
    width: 24px; }

.table-settings tr td {
  vertical-align: middle; }
  .table-settings tr td:first-child {
    width: 35%; }

.slack-setting .emoji {
  width: 15px;
  margin-left: 2px; }

.customize-surveys {
  position: relative; }
  .customize-surveys .input-group-text {
    background-color: #fff; }
    .customize-surveys .input-group-text img {
      width: 25px; }
  .customize-surveys .disable-overlay {
    background-color: rgba(242, 242, 242, 0.3);
    position: absolute;
    top: -0.5rem;
    left: -0.5rem;
    bottom: 0.5rem;
    width: 101%;
    height: 104%;
    z-index: 9; }
  .customize-surveys .tab-pane {
    margin-left: 1.5rem; }
    .customize-surveys .tab-pane > .mb-3 {
      margin-bottom: 1.8rem !important; }
      .customize-surveys .tab-pane > .mb-3 > .form-label:first-child {
        font-weight: 600; }

.segment-item {
  display: inline-block; }
  .segment-item .btn-delete {
    background-color: #dc3545;
    width: 8px;
    height: 8px;
    margin-left: .1rem;
    cursor: pointer; }

.segment-tag {
  display: inline-block;
  border: 1px solid rgba(218, 220, 226, 0.5);
  padding: .2rem .5rem;
  margin-right: .1rem;
  border-radius: .2rem;
  font-size: .85rem;
  cursor: pointer; }
  .segment-tag::after {
    display: inline-block;
    content: "+";
    padding: 0rem 0.3rem;
    background: rgba(47, 128, 237, 0.05);
    border-radius: .1rem;
    margin-left: .3rem;
    color: #2f80ed; }
  .segment-tag:hover::after {
    background: rgba(47, 128, 237, 0.1); }
  .segment-tag.added {
    opacity: .5; }
    .segment-tag.added::after {
      background-color: rgba(96, 104, 111, 0.1);
      color: #60686f; }

.segment-suggestions .segment-item {
  margin-right: .8rem;
  margin-bottom: .5rem; }

.settings-area {
  display: flex; }
  .settings-area .settings-menu {
    flex: 0 0 auto; }
  .settings-area .settings-content {
    flex: 1 0 0;
    padding-left: 1.5rem; }

.table-employee th, .table-employee td {
  vertical-align: middle;
  white-space: nowrap; }

.table-employee .profile-operator .avatar {
  width: 20px;
  height: 20px; }

.table-employee .badge {
  padding: .2rem .5rem; }

.profile-operator-option {
  display: flex !important; }
  .profile-operator-option .avatar {
    width: 35px;
    height: 35px; }
  .profile-operator-option .name {
    line-height: 1; }
  .profile-operator-option .email {
    font-size: .8rem; }

.view-api-key .card .nav-link {
  color: #3C404C;
  text-decoration: underline; }
  .view-api-key .card .nav-link:hover {
    text-decoration: none; }
  .view-api-key .card .nav-link.active {
    color: #2f80ed;
    text-decoration: none;
    cursor: auto; }

.view-employee .settings-content {
  overflow-x: auto; }
