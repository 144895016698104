.external-survey {
  padding: 1rem; }
  .external-survey .centered-content-wrapper {
    padding: 2rem 0;
    max-width: 36rem;
    margin: 0 auto;
    background-color: transparent;
    justify-content: flex-start;
    height: auto; }
  .external-survey-heading {
    margin-bottom: 3rem;
    text-align: center;
    color: #757d92; }
    .external-survey-heading .company-logo {
      height: 1.5rem;
      margin-bottom: 1rem; }
    .external-survey-heading .page-title {
      font-size: 1.4rem; }
  .external-survey .card {
    width: 100%;
    margin-bottom: 2rem;
    text-align: center; }
    .external-survey .card-body {
      padding-left: .8rem;
      padding-right: .8rem; }
    .external-survey .card-title {
      font-size: 1.1rem;
      font-weight: 500; }
    .external-survey .card-subtitle {
      color: #60686f;
      font-size: .95rem; }

.external-rating {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  margin-top: 1rem; }
  .external-rating-item {
    flex: 1 0 auto;
    padding: .8rem .5rem;
    min-width: 5rem;
    height: 5rem;
    border: 1px solid rgba(218, 220, 226, 0.5);
    border-radius: 1rem;
    text-align: center;
    filter: grayscale(1) opacity(0.5);
    transition: all .3s ease; }
    .external-rating-item.selected, .external-rating-item:hover {
      filter: none; }
    .external-rating-item-symbol img {
      height: 1.5rem; }
    .external-rating-item-text {
      display: block;
      margin-top: .5rem;
      font-weight: 600; }

@media screen and (max-width: 625px) {
  .external-survey-heading {
    margin-bottom: 1.5rem; }
  .external-survey .card-subtitle {
    font-size: .9rem; }
  .external-rating {
    gap: .5rem; }
    .external-rating-item {
      min-width: auto;
      height: auto;
      padding: .4rem .2rem;
      border: 0; }
      .external-rating-item-symbol img {
        height: 1rem; }
      .external-rating-item-text {
        margin-top: 0;
        font-size: .75rem;
        font-weight: normal; } }
