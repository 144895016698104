.view-surveys .table-wrapper .card-loading {
  top: 0;
  height: 100%;
  right: 0; }

.table-all-surveys {
  min-width: 1100px; }
  .table-all-surveys th {
    white-space: nowrap; }
  .table-all-surveys .profile-operator .avatar {
    width: 25px;
    height: 25px; }
  .table-all-surveys .rating-emoji img {
    width: 20px; }
  .table-all-surveys .badge-nps {
    display: inline-block;
    font-size: .7rem; }
  .table-all-surveys .comment {
    display: inline-block;
    width: 20rem;
    text-decoration: underline;
    cursor: pointer; }
