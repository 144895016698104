.top-bar {
  position: fixed;
  width: 100%;
  z-index: 999;
  background-color: #fff;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.08); }
  .top-bar .web-info .web-logo {
    max-height: 1.8rem;
    margin-right: .5rem; }
  .top-bar .web-info small {
    color: #2f80ed;
    margin-left: .3rem; }
  .top-bar .navbar {
    --bs-navbar-padding-y: 0;
    box-shadow: 0px 2px 19px rgba(0, 0, 0, 0.08); }
  .top-bar .navbar-nav .nav-link {
    font-size: .875rem;
    font-weight: 500;
    color: #3C404C; }
  .top-bar .navbar-nav > .nav-item, .top-bar .navbar-nav > .dropdown {
    margin-left: 1rem; }
  .top-bar .navbar-nav .dropdown-item a.active {
    color: #2f80ed; }
    .top-bar .navbar-nav .dropdown-item a.active .icon {
      background-color: #2f80ed; }
  .top-bar .btn-upgrade {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    border-radius: 2rem;
    font-weight: 600; }
    .top-bar .btn-upgrade, .top-bar .btn-upgrade.active {
      color: #fff !important;
      background-color: #2f80ed;
      border-color: #2f80ed; }
    .top-bar .btn-upgrade:hover {
      background-color: #1872eb;
      color: #fff;
      border-color: #1872eb; }
    .top-bar .btn-upgrade .icon {
      font-size: 1.1rem;
      position: relative;
      top: -2px;
      margin-right: .2rem; }
  .top-bar .navbar-brand {
    margin: 0; }
  .top-bar .global-alert {
    display: flex;
    align-items: center;
    padding: .3rem .6rem;
    margin-bottom: 0; }
    .top-bar .global-alert .text {
      line-height: 21px; }
    .top-bar .global-alert .btn {
      margin-left: .8rem;
      padding: .1rem .4rem;
      font-size: .8rem;
      border-radius: .2rem; }
