.rdrMonthAndYearPickers select {
  padding: 7px 30px 7px 10px; }

.rdrSelected, .rdrInRange, .rdrStartEdge,
.rdrEndEdge, .rdrDayToday .rdrDayNumber span:after {
  background: #2f80ed; }

.rdrDateDisplayItemActive,
.rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview,
.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after {
  border-color: #2f80ed; }

.date-interval-dropdown a {
  cursor: pointer; }

.date-interval-dropdown .dropdown-toggle {
  padding-top: .45rem;
  padding-bottom: .45rem; }
  .date-interval-dropdown .dropdown-toggle, .date-interval-dropdown .dropdown-toggle:hover, .date-interval-dropdown .dropdown-toggle:focus {
    background-color: #fff;
    color: #212529;
    border-color: rgba(218, 220, 226, 0.5); }

.date-interval-dropdown .dropdown-item .rdrDateRangePickerWrapper {
  color: #3C404C; }

.date-interval-dropdown .dropdown-item.active {
  color: #2f80ed;
  background-color: transparent; }

.date-interval-dropdown .dropdown-item:hover, .date-interval-dropdown .dropdown-item:focus, .date-interval-dropdown .dropdown-item:active {
  outline: 0;
  background-color: transparent;
  color: #3C404C; }

.rdrDefinedRangesWrapper {
  display: none; }
