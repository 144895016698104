.left-navigation {
  width: 12.5rem;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 .5rem 1rem .5rem;
  height: 100%;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #fff;
  overflow-y: auto; }
  .left-navigation::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    background-color: #fff; }
  .left-navigation::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #fff; }
  .left-navigation::-webkit-scrollbar-thumb {
    background-color: rgba(218, 220, 226, 0.5);
    border-radius: 2px; }
  .left-navigation > .nav {
    margin-bottom: 1rem; }
  .left-navigation .nav .nav .nav-item .nav-link {
    padding-left: 2.1rem; }
  .left-navigation .nav-link {
    padding-left: .5rem;
    padding-right: .5rem;
    cursor: pointer;
    font-size: .875rem;
    font-weight: 500; }
    .left-navigation .nav-link, .left-navigation .nav-link:focus {
      color: #3C404C; }
    .left-navigation .nav-link:hover {
      color: #2f80ed; }
    .left-navigation .nav-link.active {
      color: #2f80ed;
      background-color: rgba(47, 128, 237, 0.1);
      border-radius: .3rem; }
    .left-navigation .nav-link .icon-duo {
      position: relative;
      top: 1px; }
    .left-navigation .nav-link span {
      margin-left: .3rem; }
  .left-navigation .testimonial-cta {
    padding: .8rem .35rem .8rem .5rem;
    border-top: 1px solid rgba(218, 220, 226, 0.5); }
    .left-navigation .testimonial-cta .btn-close {
      display: block;
      margin-left: auto;
      margin-bottom: .5rem;
      font-size: .5rem; }
    .left-navigation .testimonial-cta .cta-title {
      font-size: 0.82031rem; }
    .left-navigation .testimonial-cta .testimonial-top {
      position: relative; }
      .left-navigation .testimonial-cta .testimonial-top img {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem; }
      .left-navigation .testimonial-cta .testimonial-top .info {
        position: absolute;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: .5rem 1rem .5rem .5rem;
        color: #fff; }
        .left-navigation .testimonial-cta .testimonial-top .info .icon {
          font-size: 2.5rem; }
        .left-navigation .testimonial-cta .testimonial-top .info .name, .left-navigation .testimonial-cta .testimonial-top .info .title {
          display: block; }
        .left-navigation .testimonial-cta .testimonial-top .info .name {
          font-weight: 700;
          line-height: 1; }
        .left-navigation .testimonial-cta .testimonial-top .info .title {
          font-size: 0.82031rem; }
    .left-navigation .testimonial-cta .testimonial-bottom {
      color: #858B9E;
      font-size: 0.82031rem;
      border: 1px solid rgba(218, 220, 226, 0.5);
      padding: .5rem;
      line-height: 1.25;
      border-bottom-left-radius: 0.25rem;
      border-bottom-right-radius: 0.25rem; }
