.view-reviews .metric-simple .title {
  font-size: .9rem;
  margin-bottom: 0; }

.view-reviews .metric-simple .value {
  font-size: 1rem; }

.view-reviews .metric-simple-group {
  display: flex;
  justify-content: space-between;
  gap: 1rem; }
  .view-reviews .metric-simple-group .card {
    flex: 1 1 33.33%; }
    .view-reviews .metric-simple-group .card .card-body {
      padding-top: .5rem;
      padding-bottom: .5rem; }

.view-reviews .review-stats-area .card {
  margin-bottom: 1rem !important; }

.view-reviews .rdw-editor-wrapper {
  margin-bottom: 1rem; }

.view-reviews .rdw-editor-main {
  height: 20rem; }

.view-reviews .date-interval-dropdown, .view-reviews .date-interval-dropdown .dropdown-toggle {
  width: 100%; }

.view-reviews .date-interval-dropdown .dropdown-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.review-editor-area {
  position: relative; }
  .review-editor-area .loading-indicator {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 6px; }
  .review-editor-area .rdw-editor-main {
    height: 20rem; }
