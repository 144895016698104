.view-admin .admin-title {
  font-size: 1.1rem;
  margin-bottom: 1.5rem; }

.view-admin .table th {
  font-weight: 600; }

.view-admin .table-wrapper .card-loading {
  height: 100%;
  top: 0; }
